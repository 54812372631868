@import "variables";

@import '~bootstrap/scss/bootstrap';

// @import "~font-awesome/scss/font-awesome";
// @import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);

@font-face {
  font-family: "Gotham-Bold";
  src: url('/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/Gotham-Bold.otf') format('opentype'),
    url('/fonts/Gotham-Bold.woff') format('woff'), url('/fonts/Gotham-Bold.ttf') format('truetype'), url('/fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("/fonts/Gotham-Medium.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Light';
  src: url('/fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'), url('/fonts/Gotham-Light.otf') format('opentype'),
    url('/fonts/Gotham-Light.woff') format('woff'), url('/fonts/Gotham-Light.ttf') format('truetype'), url('/fonts/Gotham-Light.svg#Gotham-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Gotham-Medium", sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  padding-bottom: 150px;
}

.key {
  text-transform: capitalize;
  font-weight: bold;
}

.logo {
  width: 60px;
}

.navbar-brand {
  padding-bottom: 0 !important;
}

.navbar.bg-dark {
  // background-color: #00274C !important;
  background-color: #2F93FF !important;
}

.navbar.bg-dark .active a.nav-link {
  color: #FFCB05;
}

.navbar.bg-dark a.nav-link {
  color: #ffffff;
}

a.nav-link {
  font-family: "Gotham-Bold", sans-serif;
  letter-spacing: 1px;
}

h1 {
  font-size: 2rem;
  color: #504D4D;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #2F93FF;
  color: #ffffff;
  z-index: 9;
}

.admin-page {
  margin: 10px 0 50px 0;
}

table.dataTable {
  border-collapse: collapse !important;

  &#sellsTable .actions {
    min-width: 120px;
  }
}

.list-group-item {
  strong {
    width: 150px;
    display: inline-block;
  }

  &.photos-list {
    img {
      max-width: 100%;
    }

    strong {
      width: auto;
    }
  }

  @media (max-width: $mobile) {
    &.photos-list {
      text-align: center;
    }
  }
}

.card {
  .card-link {
    @media (max-width: $tablet-max) {
      display: block;
      margin-left: 0;
      margin-bottom: 6px;
    }
  }
}

.error {
  margin-top: 10px;
  color: #ff0000;
  font-size: 14px;
  line-height: 20px;
}

.form-control {
  &.error {
    border-color: #ff0000;
  }
}

#NotesWrapper {
  max-height: 40vh;
  overflow: auto;

  .list-group {
    margin-bottom: 10px;
  }
}

.image-popup {
  cursor: pointer;
}

.image-modal {
  .image-preview {
    max-width: 100%;
  }
}

a.addnew {
  float: right;
}

.pull-right {
  float: right
}

a.btn.btn-secondary.nav-link.addnew {
  background-color: #007bff;
  border-color: #007bff;
}

li.addnew {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 18px;
}

a.addcheck {
  border: none;
  color: #007bff !important;
  display: unset !important;
}

.seller {
  margin-bottom: 0px !important;
}

img.inventoryimage {
  height: 50px;
}

.bg-dark {
  background-color: #B0E0E6 !important;
}

.bg-secondary {
  background-color: #FFE4C4 !important;
}

.bg-warning {
  background-color: #90EE90 !important;
}

.bg-info {
  background-color: #00FFFF !important;
}

.bg-danger {
  background-color: #FF6347 !important;
}

.bg-primary {
  background-color: #20B2AA !important;
}

.bg-success {
  background-color: #B0C4DE !important;
}


.table-scroll {
  max-height: 200px;
  overflow: scroll;
}

.table-scroll table {
  text-align: left;
  position: relative;
}

.table-scroll th {
  background: white;
  position: sticky;
  top: 0;
}

.noselect {
  pointer-events: none;
}