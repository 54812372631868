
// Body
$body-bg: #ffffff;

// Typography
$font-family-base: "Gotham-Light", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

//Breakout points
$mobile: 480px;
$mobile-max: 767px;
$tablet-min: 768px;
$tablet: 992px;
$tablet-max: 1023px;
$laptop-min: 1024px;
$laptop: 1150px;
$laptop-max: 1365px;
